import { instance, setToken, deleteToken } from "./instance";

/*
 * POST @ /auth/login
 * body: { login, password }
 */
export const logIn = async (user) => {
    try {
        const res = await instance.post("/auth/login", user);
        console.log("logIn res=", res);
        // After successful login, add the token to the HTTP header
        setToken(res.data.token);
        return res.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

/*
 * POST @ /users/logout
 * headers: Authorization: Bearer token
 */
export const logOut = async () => {
    try {
        await instance.post("/auth/logout");
        // After a successful logout, remove the token from the HTTP header
        deleteToken();
    } catch (error) {
        throw new Error(error.message);
    }
};
