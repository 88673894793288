import { createAsyncThunk } from "@reduxjs/toolkit";
import * as servicesAPI from "../../api/services";
import * as workhoursAPI from "../../api/workhours";
import * as doctorsAPI from "../../api/doctors";

export const getDirectories = createAsyncThunk("directories/get", async (nameDirectory, thunkAPI) => {
    try {
        let response;
        switch (nameDirectory){
            // case "users":
            //     // console.log("users")

            //     break;

            case "workhours":
                response=await  workhoursAPI.apiGetWorkhours();
                break;

            case "doctors":
                response=await  doctorsAPI.apiGetDoctors();
                break;

            case "services":
                response=await  servicesAPI.apiGetServices()
                break;

            default:
                console.log("Invalid directory type");
        }  

        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const createDirectory = createAsyncThunk("directories/create", async ({nameDirectory, query}, thunkAPI) => {
    try {
        let response;
        switch (nameDirectory){
            // case "users":
            //     // console.log("users")

            //     break;

            case "workhours":
                response=await  workhoursAPI.apiAddWorkhour(query);
                break;

            case "doctors":
                response=await  doctorsAPI.apiAddDoctor(query);
                break;

            case "services":
                response=await  servicesAPI.apiAddService(query)
                break;

            default:
                console.log("Invalid directory type");
        }  

        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const updateDirectory = createAsyncThunk("directories/update", async ({nameDirectory, query, id}, thunkAPI) => {
    try {

        let response;
        switch (nameDirectory){
            // case "users":
            //     // console.log("users")

            //     break;

            case "workhours":
                response=await  workhoursAPI.apiUpdateWorkhour({...query}, id);
                break;

            case "doctors":
                response=await  doctorsAPI.apiUpdateDoctor({...query}, id);
                break;

            case "services":
                response=await  servicesAPI.apiUpdateService({...query}, id)
                break;

            default:
                console.log("Invalid directory type");
        }  

        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }

});

export const deleteDirectory = createAsyncThunk("directories/delete", async ({nameDirectory,id}, thunkAPI) => {
    console.log("deleteDirectory nameDirectory=", nameDirectory)
    console.log("deleteDirectory id=", id)
    try {
        let response;
        switch (nameDirectory){
            // case "users":
            //     // console.log("users")

            //     break;

            case "workhours":
                response= await workhoursAPI.apiDeleteWorkhour(id);
                break;

            case "doctors":
                response = await doctorsAPI.apiDeleteDoctor(id);
                break;

            case "services":
                response = await servicesAPI.apiDeleteService(id)
                break;

            default:
                console.log("Invalid directory type");
        }  

        console.log("deleteDirectory response=", response)
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

