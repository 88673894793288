import { instance, setToken } from "./instance";

/*
 * GET @ /users/current
 * headers: Authorization: Bearer token
 */
export const refresh = async (token) => {
    // console.log("persistedToken", token);
    try {
        setToken(token);
        const { data } = await instance.get("api/users/current");
        // console.log("response data in users.js : ", data);
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};

// export const edit = async user => {
//     try{
//         const {data} = await instance.patch('/users/edit', user);
//         return data;
//     }catch (error){
//         throw new Error(error.message);
//     }
// };
