import { Link, useLocation } from 'react-router-dom';
import {Tooltip} from 'react-tooltip';
import sprite from "../../../assets/icons/icons.svg";
import css from "./LinkAddBut.module.css";
import style from "../LinkBut.module.css";

const LinkAddBut =({src, tooltipId, tooltipContent, icon})=>{
    const location = useLocation();

    return(
        <div className={css.wrapper}>
            <Link 
                to={src}
                state={{ from: location }} 
                data-tooltip-id={tooltipId}
                data-tooltip-content={tooltipContent}
                className={style.link}
            >
                <svg className={css.icon}>
                    
                    <use href={`${sprite}#${icon}`}></use>
                </svg>
                <Tooltip id={tooltipId} place="bottom" style={{ backgroundColor: "#rgba(220, 227, 229, 0.50)", borderRadius: "8px", color: "#FFFFFF"}}/>
            </Link>
        </div>
    );

}

export default LinkAddBut;