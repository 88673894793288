export const baseURL = "https://calendar.eglamed.com.ua"; //** !!!  для ХОСТИНГУ !!! */
// export const baseURL = "http://localhost:4000"; //** для локалхосту */
// export const baseURL = "http://192.168.128.101:4000"; //** для WAN (mobile devices)*/
//
// const daysOfWeekEngl = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
// const daysOfWeekShortEngl = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
export const daysForSchedule = ["Понеділок", "Вівторок", "Середа", "Четвер", "Пʼятниця", "Субота"];
export const daysOfWeek = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"];

export const titles = {
    time: "Час прийому",
    active: "Статус активності",
    description: "Опис послуги",
    price: "Ціна",
    name: "Ім'я",
    surname: "Прізвище",
};
