import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import { useDispatch } from "react-redux";
import Logo from "../../ui/Logo/Logo";
import ButLogin from "../../ui/buttons/ButLogin/ButLogin";
import { logInUser } from "../../redux/auth/authOperations";
import css from "./LoginForm.module.css";
import NameApp from "../NameApp/NameApp";

const initialValues = {
    login: "",
    password: "",
};

const userSchema = object({
    login: string().min(6).max(75).required("Login is a required field"),
    password: string().min(6).max(100).required("Password is a required field"),
});

const LoginForm = () => {
    const dispatch = useDispatch();

    const handleSubmit = (values, { resetForm }) => {
        dispatch(logInUser(values)).then((_) => {
            resetForm();
        });
    };

    const FormError = ({ name }) => {
        return <ErrorMessage name={name} render={(msg) => <p className={`${css.msgText} ${css.errorMsg}`}>{msg}</p>} />;
    };

    return (
        <div className={css.mainContainer}>
            <div className={css.formContainer}>
                <div className={css.logoWrapper}>
                    <Logo width={50} height={50} />
                    <NameApp />
                </div>
                <h2 className={css.title}>Вхід</h2>
                <Formik initialValues={initialValues} validationSchema={userSchema} onSubmit={handleSubmit}>
                    <Form>
                        <label htmlFor="user_login" className={css.label}>
                            <span className={css.labelText}>
                                Логін
                                <span className={css.accent}>*</span>
                            </span>
                            <Field autoComplete="off" type="login" name="login" id="user_login" placeholder="Enter login" className={css.input} />

                            <FormError name="login" />
                        </label>

                        <label htmlFor="user_password" className={css.label}>
                            <span className={css.labelText}>
                                Пароль
                                <span className={css.accent}>*</span>
                            </span>

                            <Field autoComplete="off" type="password" name="password" id="user_password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" className={css.input} />

                            <FormError name="password" />
                        </label>
                        <ButLogin />
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default LoginForm;
