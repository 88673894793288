import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datePicker.css";
import toast, { Toaster } from "react-hot-toast";
import { FiBell } from "react-icons/fi";
import Button from "../../ui/buttons/Button/Button";
import * as schedulesAPI from "../../api/schedules";
import * as doctorsAPI from "../../api/doctors";
import * as workhoursAPI from "../../api/workhours";
import { useAuth } from "../../hooks/useAuth";
import { createSchedule } from "../../redux/schedules/schedulesOperations";
import isSunday from "../../services/isSunday";
import "../../styles/variables.css";
import css from "./ScheduleForm.module.css";

const ScheduleForm = ({ type }) => {
    let initialValues = null;
    if (type === "add") {
        initialValues = {
            date: new Date(),
            time: [],
            doctor: "",
        };
    }

    const [freeWorkingTime, setFreeWorkingTime] = useState([]);
    const [workhours, setWorkHours] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [schedules, setSchedules] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [isLoadingSchedules, setIsLoadingSchedules] = useState(false);

    const dispatch = useDispatch();
    const { user } = useAuth();

    useEffect(() => {
        if (!selectedDate) {
            return;
        }

        const getSchedulesByDate = async (date) => {
            try {
                const schedulesForDate = await schedulesAPI.apiGetSchedules({ date: date.toISOString().slice(0, 10) });
                if (schedulesForDate.length > 0) {
                    setSchedules(schedulesForDate);
                }
            } catch (error) {
                const errorMessage = "Помилка при завантаженні графіку за дату :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                toast.error(errorMessage);
            }
        };

        const getDoctors = async () => {
            try {
                const apiDoctors = await doctorsAPI.apiGetDoctors();
                const activeDoctors = apiDoctors.filter((doctor) => doctor.active === true);
                setDoctors(activeDoctors);
            } catch (error) {
                const errorMessage = "Помилка при завантаженні лікарів :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                toast.error(errorMessage);
            }
        };

        const getWorkhours = async () => {
            try {
                const apiWorkHours = await workhoursAPI.apiGetWorkhours();
                const activeWorkHours = apiWorkHours.filter((workHour) => workHour.active === true);
                setWorkHours(activeWorkHours);
            } catch (error) {
                const errorMessage = "Помилка при завантаженні робочих годин :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                toast.error(errorMessage);
            }
        };

        getDoctors();
        getWorkhours();
        getSchedulesByDate(selectedDate);
    }, [selectedDate]);

    const handleSubmit = (values, { resetForm }) => {
        // console.log("values=", values)
        if (values.time.length === 0) {
            toast.error(`Оберіть час запису`, {
                duration: 3000,
                position: "top-center",
                icon: <FiBell size={24} color={getComputedStyle(document.documentElement).getPropertyValue("--accent-color")} />,
            });
        } else {
            const queryDate = values.date.toISOString().slice(0, 10);
            let query = null;

            try {
                if (type === "add") {
                    for (let i = 0; i < values.time.length; i += 1) {
                        query = {
                            ...values,
                            date: queryDate,
                            time: values.time[i],
                            doctor_id: selectedDoctor.doctor_id,
                            user_id: user.user_id,
                        };
                        delete query.doctor;

                        for (const key in query) {
                            if (query[key] === "") {
                                delete query[key];
                            }
                        }
                        dispatch(createSchedule(query));
                    }
                }

                toast.success(`Відправка форми`, {
                    duration: 3000,
                    position: "top-center",
                    icon: <FiBell size={24} color={getComputedStyle(document.documentElement).getPropertyValue("--accent-color")} />,
                });
            } catch (error) {
                const errorMessage = "Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                toast.error(errorMessage);
            }
            handleCancel(resetForm);
        }
    };

    const handleCancel = (resetForm) => {
        setSelectedDate(null);
        setFreeWorkingTime([]);
        setSchedules([]);
        setSelectedDoctor(null);
        setDoctors([]);
        setIsLoadingSchedules(false);
        resetForm();
    };

    //если это не воскресенье, то выбранная в календаре дата отрисовывается в input
    const handleDateChange = (date, setFieldValue) => {
        if (!isSunday(date)) {
            setFieldValue("date", date);
            setSelectedDate(date);
        }
    };

    const handleDoctorChange = async (e) => {
        // console.log(e.target)
        const selectedDoc = e.target.value;
        // console.log("selectedDoc=", selectedDoc)

        const choosedDocObject = doctors.find((doc) => selectedDoc === `${doc.name} ${doc.surname}`);
        setSelectedDoctor(choosedDocObject);

        const freeWorkingTimeForDoctor = [];

        workhours.forEach(function (workhour, index) {
            const isInSchedule = schedules?.find((schedule) => schedule.time === workhour.time);

            if (!isInSchedule) {
                freeWorkingTimeForDoctor.push(workhour);
            }
        });

        setFreeWorkingTime(freeWorkingTimeForDoctor);

        setIsLoadingSchedules(true);
    };

    return (
        <div className="container">
            <h3 className={css.formTitle}>Форма запису в графік</h3>
            <Toaster />
            <Formik initialValues={initialValues} onSubmit={handleSubmit} isValidating>
                {({ values, setFieldValue, resetForm }) => (
                    <Form>
                        <label htmlFor="date" className={css.formLabel}>
                            <p className={css.calendarText}>
                                Оберіть дату запису у графік<span className={css.accent}>*</span>
                            </p>
                        </label>
                        <Field name="date">
                            {
                                // ({ field }) => (
                                // <DatePicker
                                //     showIcon
                                //     toggleCalendarOnIconClick
                                //     selected={field.value}
                                //     // selected={selectedDate}
                                //     onChange={(date) => handleDateChange(date, setFieldValue)}
                                //     locale="uk"
                                //     dateFormat="dd.MM.yyyy"
                                //     shouldCloseOnSelect={true} //не закрывается при выборе даты
                                //     // inline//без инпута
                                //     closeOnScroll={true}
                                //     disabled={isLoadingSchedules}
                                //     minDate={new Date()}
                                //     //воскресеньям добавляется класс стилей sunday
                                //     dayClassName={(date) => (isSunday(date) ? "sunday" : undefined)}
                                // />
                                // )
                                (field) => {
                                    const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
                                        <button className={className} onClick={onClick} ref={ref}>
                                            {value}
                                        </button>
                                    ));
                                    return (
                                        <DatePicker
                                            selected={field.field.value}
                                            onChange={(date) => handleDateChange(date, setFieldValue)}
                                            customInput={<ExampleCustomInput className="example-custom-input" />}
                                            shouldCloseOnSelect={true}
                                            locale="uk"
                                            dateFormat="dd MMMM yyyy"
                                            // воскресеньям добавляется класс стилей sunday
                                            dayClassName={(date) => (isSunday(date) ? "sunday" : undefined)}
                                        />
                                    );
                                }
                            }
                        </Field>

                        {/* выводится список докторов из графика на выбранную дату*/}
                        {selectedDate && (
                            <>
                                <div className={css.line}></div>
                                <div id="my-radio-group">
                                    Оберіть лікаря<span className={css.accent}>*</span>
                                </div>
                                <div role="group" aria-labelledby="my-radio-group" className={css.doctorsWrapper}>
                                    {doctors.map(({ doctor_id, name, surname }) => (
                                        <label className={css.customRadio} key={`doctor${doctor_id}`}>
                                            <Field type="radio" name="doctor" value={`${name} ${surname}`} onClick={(doctor) => handleDoctorChange(doctor)} />
                                            <span>{`${name} ${surname}`}</span>
                                        </label>
                                    ))}
                                </div>
                            </>
                        )}

                        {freeWorkingTime?.length > 0 && (
                            <>
                                <div className={css.line}></div>
                                <div id="checkbox-group" className={css.timeHeader}>
                                    Оберіть час прийому<span className={css.accent}>*</span>
                                </div>
                                <div role="group" aria-labelledby="checkbox-group">
                                    <ul className={css.timeWrapper}>
                                        {freeWorkingTime?.map(({ workhour_id, time }) => {
                                            return (
                                                <li key={workhour_id}>
                                                    <Field type="checkbox" id={`time${workhour_id}`} name="time" value={time} className={css.timeInput} />
                                                    <label htmlFor={`time${workhour_id}`} className={css.timeLabel}>
                                                        {time.slice(0, 5)}
                                                    </label>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div className={css.line}></div>

                                <div className={css.butWrapper}>
                                    <Button type="submit">Додати у графік</Button>
                                    <Button type="button" onClick={() => handleCancel(resetForm)}>
                                        Відміна
                                    </Button>
                                </div>
                            </>
                        )}
                        {isLoadingSchedules && freeWorkingTime?.length === 0 && (
                            <>
                                <p className={css.timeInfo}>Немає вільних місць на обрану дату</p>
                                <Button type="button" onClick={() => handleCancel(resetForm)}>
                                    Відміна
                                </Button>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ScheduleForm;
