import { createSlice } from "@reduxjs/toolkit";
import { createDirectory, getDirectories, deleteDirectory, updateDirectory } from "./directoriesOperations";
import { logOutUser } from "../auth/authOperations";

export const initialState = {
    items: [],
    isLoading: false,
    error: null,

};

export const handlePending = (state, action) => {
    state.isLoading = true;

};

export const handleRejected = (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
};

export const handleFulfilled = (state, action) => {
    state.items = action.payload;
    state.isLoading = false;
    state.error = null;
};


export const handleCreateFulfilled = (state, action) => {
    state.items.push(action.payload);
    state.error = null;
};

export const handleDeleteFulfilled = (state, action) => {
    state.isLoading = false;
    state.error = null;
    const keys= Object.keys(state.items[0]);
    console.log("handleDeleteFulfilled keys=", keys)
    const nameId=keys[0];
    console.log("handleDeleteFulfilled nameId=", nameId)
    console.log("handleDeleteFulfilled action.payload=", action.payload)
    console.log("handleDeleteFulfilled state.items=", state.items)
    const index = state.items.findIndex(item => item[nameId] === action.payload[nameId]);
    state.items.splice(index, 1);
};

const handleUpdateFulfilled = (state, action) =>{
    console.log("handleUpdateFulfilled state.items=", state.items)
    const keys= Object.keys(state.items[0]);
    const nameId=keys[0];
    console.log("handleUpdateFulfilled action.payload=", action.payload)
    let index;
    if(action.payload){
        index = state.items.findIndex(item => item[nameId] === action.payload[nameId]);
        state.items.splice(index, 1, action.payload);
        state.isLoading = false;
        state.error = null;
    }

    console.log("handleUpdateFulfilled state.items=", state.items)
    console.log("handleUpdateFulfilled ");
  }

const handleLogOutFulfilled = (state)=>{
    state.items = [];
    state.error = null;
    state.isLoading = false;
};

const directoriesSlice = createSlice({
    name: "directories",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDirectories.pending, handlePending)
            .addCase(getDirectories.fulfilled, handleFulfilled)
            .addCase(getDirectories.rejected, handleRejected)
            .addCase(deleteDirectory.pending, handlePending)
            .addCase(deleteDirectory.fulfilled, handleDeleteFulfilled)
            .addCase(deleteDirectory.rejected, handleRejected)
            .addCase(createDirectory.pending, handlePending)
            .addCase(createDirectory.rejected, handleCreateFulfilled)
            .addCase(createDirectory.fulfilled, handleRejected)
            .addCase(updateDirectory.pending, handlePending)
            .addCase(updateDirectory.fulfilled, handleUpdateFulfilled)
            .addCase(updateDirectory.rejected, handleRejected)
            .addCase(logOutUser.fulfilled, handleLogOutFulfilled);

    },
});



export const directoriesReducer = directoriesSlice.reducer;