// import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Toaster } from "react-hot-toast";
// import {useAppointment} from "../../hooks/useAppointment";
import {useDirectory} from "../../hooks/useDirectory";
import Button from "../../ui/buttons/Button/Button";
// import MessageNotification from "../MessageNotification/MessageNotification";
import {deleteAppointment} from "../../redux/appointments/operations";
import {deleteSchedule} from "../../redux/schedules/schedulesOperations";
import {deleteDirectory} from "../../redux/directories/directoriesOperations";
import css from "./ModalDelete.module.css";

const ModalDelete = ({currentItem, onClose, text, type})=>{

    const dispatch = useDispatch();

    // const {appointmentError, appointmentStatus} = useAppointment();

    // useEffect(() => {
    //     console.log("ModalDelete useEffect appointmentError=", appointmentError)
    //     console.log("ModalDelete useEffect appointmentStatus=", appointmentStatus)
    //     if(appointmentError){
    //        MessageNotification("error", `ModalDelete Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${appointmentError}`);
    //     }
    //     if(appointmentStatus===200 && appointmentError===null){
    //         MessageNotification("success",`ModalDelete Зміни внесені`);
    //     }
  
    // },[appointmentError, appointmentStatus]);

    const onClickDelete=()=>{
        if (type==="appointment"){
            dispatch(deleteAppointment(currentItem.appointment_id));
        }

        if(type==="schedule"){
            dispatch(deleteSchedule(currentItem.schedule_id));
        }
        if(type==="directory"){
            const arr=Object.keys(currentItem);
            const nameID=arr[0].slice(0,arr[0].length-3);
            const id=currentItem[arr[0]];
            const nameDirectory=nameID+"s";
            dispatch(deleteDirectory({nameDirectory, id}));
        }
        onClose();
    }

    const onClickCancel=()=>{
        onClose();
    }

       return (
            <div className={css.wrapper}>
            <Toaster/>
            <p>{text}</p>
            <div className={css.butWrapper}> 
                <Button 
                    type="button" 
                    onClick={onClickDelete}
                    className="ok"
                >
                    Видалити
                </Button>
                <Button
                    type="button"
                    onClick={onClickCancel}
                    className="cancel"
                >
                    Відміна
                </Button>
            </div>
            </div>
    );
}

export default ModalDelete;