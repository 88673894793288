import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import * as workhoursAPI from "../../api/workhours";
import * as servicesAPI from "../../api/services";
import * as doctorsAPI from "../../api/doctors";
import * as usersAPI from "../../api/users";
import * as schedulesAPI from "../../api/schedules";
import * as appointmentsAPI from "../../api/appointments";
import isRecord from "../../services/isRecord";
import DirectoryToolbar from '../DirectoryToolbar/DirectoryToolbar';
import LinkWithIcon from '../../ui/LinkButtons/LinkWithIcon/LinkWithIcon';
import {getDirectories} from "../../redux/directories/directoriesOperations";
import {useDirectory} from "../../hooks/useDirectory";
import {titles} from "../../constants";
import css from "./Directories.module.css";

const Directories =()=>{
    const location = useLocation();
    const dispatch = useDispatch();

    const {directory}=useDirectory();

    const [header, setHeader]=useState([]);
    const [appointments, setAppointments]=useState([]);
    const [schedules, setSchedules]=useState([]);
    

    useEffect(() => {
        dispatch(getDirectories(location.state.item));


        // const fetchApi=(async ()=>{ 
        //     switch (location.state.item){
        //         case "users":
        //             // console.log("users")
        //             setHeader("Користувачі")
        //             break;
    
        //         case "workhours":
        //             try{
        //                 // const response=await  workhoursAPI.apiGetWorkhours();
        //                 dispatch(getDirectories("workhours"));
        //                 // setDirectory(response);
        //                 setHeader("Робочі години");
        //             }catch(error) {
        //                 const errorMessage="Помилка при завантаженні робочих годин :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
        //                 toast.error(errorMessage);
        //             };
        //             break;
    
        //         case "doctors":
        //             try{
        //                 dispatch(getDirectories("doctors"));
        //                 // const response=await  doctorsAPI.apiGetDoctors();
        //                 // setDirectory(response);
        //                 setHeader("Лікарі");

        //             }catch(error) {
        //                 const errorMessage="Помилка при завантаженні лікарів :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
        //                 toast.error(errorMessage);
        //             };
        //             break;
    
        //         case "services":
        //             try{
        //                 const response=await  servicesAPI.apiGetServices()
        //                 setDirectory(response);
        //                 setHeader("Послуги")
        //             }catch(error) {
        //                 const errorMessage="Помилка при завантаженні послуг :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
        //                 toast.error(errorMessage);
        //             };
        //             break;
    
        //         default:
        //             console.log("Invalid directory type");
        //     }             
        // })

        // fetchApi();
            
     },[location.state.item, dispatch]);

     useEffect(() => {
        
        const getAllSchedules=async()  => {
            try{
                const schedules=await schedulesAPI.apiGetSchedules();
                // console.log("Directories schedules=", schedules)
                setSchedules(schedules); 

            }catch(error){
                const errorMessage="Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
                toast.error(errorMessage);
            }

        };
        const getAllAppointments=async()=>{
            try{
                const appointments=await appointmentsAPI.apiGetAppointments();
                // console.log("Directories appointments=", appointments)
                setAppointments(appointments.data); 

            }catch(error){
                const errorMessage="Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
                toast.error(errorMessage);
            }
        }

        getAllAppointments();
        getAllSchedules();

      }, []);

    const handleCanChange=(item)=>{
        console.log("handleCanChange item=", item)
        let isCanChange=false;
        console.log('handleCanChange location.state.item=', location.state.item);
        console.log('handleCanChange appointments.length=', appointments.length);
        if(location.state.item==="services" && appointments.length>0){
            console.log('handleCanChange location.state.item==="services" && appointments.length>0');
            isCanChange=!(isRecord(item, location.state.item, appointments));
        }

        if(location.state.item!=="services" && schedules.length>0){
            isCanChange=!(isRecord(item, location.state.item, schedules));
        }
        console.log("isCanChange=", isCanChange)
 
        return isCanChange;


    }


 
    
    return(
        <>
       
        <div>
            <Toaster />
            <h2><center>{location.state.item}</center></h2>
            <div className={css.butWrapper}>
                <LinkWithIcon  
                    src={`../../add_directory`}
                    state={{ from: location, item: location.state.item}}
                    icon="icon-plus"
                    tooltipId="tooltip-add"
                    tooltipContent="Додати"
                    size="big"
                />
            </div>
          
            <ul>
                {directory?.map((item, index) => {
          
                    return(
                        <li key={index} className={css.item}>
                            <div className={css.wrapper}>  
                                <div>                                
                                       
                                {Object.entries(item).map(([key, value], i) => {
                                    let formattedValue=value;
       
                                    if(key.includes("time")){
                                        formattedValue=value.slice(0,5);
                                    }

                                    if(key.includes("active")){
                                        if(item.active){
                                            formattedValue="активно";
                                        }else{
                                            formattedValue="неактивно";
                                        }
                                    }

                                    if(key.includes('id')|| key.includes('user')|| key.includes('createdAt') || key.includes('updatedAt')){
                                        return null;
                                    }
  
                                    return( 
                                        <div key={i} className={css.textWrapper}>
                                            <span className={css.textTitle}>{titles[key]}:</span>
                                            <span className={css.text}>{formattedValue}</span> 
                                        </div>
                                    )
                                
                                })}
                                </div>   
                            <DirectoryToolbar record={item} isCanChange={handleCanChange(item)}/>
                            
                            </div>
                        </li>

                )

                })}
            </ul>



        </div>
        </>
    );

}
export default Directories;