import Button from "../Button/Button";
import { FiLogIn } from "react-icons/fi";

const ButLogin = () => {
    return (
        <Button>
            Увійти <FiLogIn />
        </Button>
    );
};

export default ButLogin;
