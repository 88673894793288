import { Suspense, useEffect, useState } from "react";
import CalendarToolbar from "../../components/CalendarToolbar/CalendarToolbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSchedule } from "../../hooks/useSchedule";
import { getSchedules } from "../../redux/schedules/schedulesOperations";
import Loader from "../../ui/Loader/Loader";

const SchedulePage = () => {
    const navigate = useNavigate();
    const { scheduleMonth, scheduleWeek, scheduleDay, scheduleYear } = useSchedule();
    const dispatch = useDispatch();

    const { pathname } = useLocation();
    const initialPeriodType = pathname.includes("/schedule/day") ? "day" : pathname.includes("/schedule/week") ? "week" : "month";
    const [periodType, setPeriodType] = useState(initialPeriodType || "month");
    const [currentDateMonth] = useState(scheduleMonth);
    const [currentDateWeek] = useState(scheduleWeek);
    const [currentDateYear] = useState(scheduleYear);

    useEffect(() => {
        if (periodType === "month") {
            dispatch(getSchedules("month"));
            navigate(`/schedule/month/${scheduleMonth}`);
        } else if (periodType === "week") {
            dispatch(getSchedules("week"));
            navigate(`/schedule/week/${currentDateWeek}`);
        } else if (periodType === "day") {
            dispatch(getSchedules("day"));
            navigate(`/schedule/day/${scheduleDay}`);
        }
    }, [navigate, periodType, scheduleMonth, currentDateWeek, scheduleDay, dispatch]);

    const checkDate = (date) => {
        const currentDate = new Date(currentDateYear, currentDateMonth, 2);
        if (date > currentDate) {
            return false;
        }
        return true;
    };

    return (
        <>
            <h2>
                <center>Розклад</center>
            </h2>
            <CalendarToolbar periodType={periodType} changePeriod={setPeriodType} checkDate={checkDate} page={"schedule"} />
            <Suspense fallback={<Loader type={"suspense"} />}>
                <Outlet context={[setPeriodType, checkDate]} />
            </Suspense>
        </>
    );
};

export default SchedulePage;
