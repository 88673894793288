import { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Logo from "../../ui/Logo/Logo";
import ButClose from "../../ui/buttons/ButClose/ButClose";
import ButLogOut from "../../ui/buttons/ButLogOut/ButLogOut";
import Navigation from "../../components/Navigation/Navigation";
import css from "./SideBar.module.css";
import NameApp from "../../components/NameApp/NameApp";

const sideBarVars = {
    initial: { x: "-100%" },
    isOn: {
        x: "0%",
        transition: {
            type: "spring",
            stiffness: 100,
            damping: 18,
        },
    },
    exit: { x: "-150%" },
};

const desktopVars = {
    initial: { x: "0%" },
    isOn: { x: "0%" },
    exit: { x: "0%" },
};

const SideBar = ({ onClose, openBtnRef }) => {
    const windowWidth = window.innerWidth;
    const isDesktop = windowWidth < 1200 ? false : true; //посмотреть для чего это
    const sideBarRef = useRef(null);

    useEffect(() => {
        const closeOutside = (event) => {
            if (sideBarRef.current) {
                if (sideBarRef.current && !sideBarRef.current.contains(event.target) && openBtnRef.current && !openBtnRef.current.contains(event.target) && !isDesktop) onClose();
            }
        };
        if (sideBarRef.current) {
            window.addEventListener("click", closeOutside);
            return () => window.removeEventListener("click", closeOutside);
        }
    }, [sideBarRef, onClose, openBtnRef, isDesktop]);

    return (
        <motion.aside className={css.aside} initial={"initial"} animate={"isOn"} exit={"exit"} variants={isDesktop ? desktopVars : sideBarVars} ref={sideBarRef}>
            <div className={css.sideBarContet}>
                <div className={css.wrapper}>
                    <Logo width={50} height={50} />
                    <NameApp />
                    {/* <p className={css.title}>EglaMed</p> */}
                    {!isDesktop && <ButClose onClose={onClose} />}
                </div>
                <Navigation onClose={onClose} />
            </div>
            <ButLogOut />
        </motion.aside>
    );
};

export default SideBar;
