import { forwardRef } from "react";
// import { useSelector } from "react-redux";
import { useLocation } from "react-router";
// import { useState } from "react";
import { MobileMenuBtn } from "../../ui/buttons/MobileMenuBtn/MobileMenuBtn";
import { UserInfo } from "../../components/UserInfo/UserInfo";
// import { useMediaQuery } from "hooks/useMediaQuery";
// import { selectCategoryTasks } from "redux/selectors";

import LinkAdd from "../../ui/LinkButtons/LinkAddBut/LinkAddBut";
import LinkGoBack from "../../ui/LinkButtons/LinkGoBack/LinkGoBack";
// import img1 from "../../assets/images/choosedday-1.png";
// import img2 from "../../assets/images/choosedday-2.png";

import css from "./Header.module.css";

export const Header = forwardRef(({ openMethod, title, isOpenSidebar }, ref) => {

    const windowWidth = window.innerWidth;
    const isNotDesktop = windowWidth<1200 ? true: false;
    const { pathname } = useLocation();
 

    const showGoBackLink = (pathname.includes("/add_appointment") || pathname.includes("/add_schedule") || pathname.includes("/admin") || pathname.includes("/add_directory") || pathname.includes("/edit_directory")) ? true : false;

    return (
        <header className={css.header}>
            <div className={css.wrapper}>
                {isNotDesktop ? (
                    <MobileMenuBtn ref={ref} onClick={openMethod} disabled={isOpenSidebar && true} />
                ) : (
                    <div className={css.titleWrapper}>
                        {/* {isUndone && isDayPage && <img srcSet={`${img1} 1x, ${img2} 2x`} src={img1} alt="motivation to do undone tasks" width={64} />} */}
                        {/* <div className={css.motivationWrapper}>
                            <h1 className={css.title}>{title}</h1>
                            {isUndone && isDayPage && <p className={css.motivationText}><span>Let go</span> of the past and focus on the present!</p>}
                            </div> */}
                    </div>
                )}
                {showGoBackLink ? <LinkGoBack /> : 
                    <>
                        <LinkAdd src="/add_appointment" tooltipId="tooltip-add-app" tooltipContent="Запис на прийом" icon="icon-doctor-appointment"/>
                        <LinkAdd src="/add_schedule" tooltipId="tooltip-add-schedule" tooltipContent="Запис у графік" icon="icon-deadline"/>
                    </>
                }
                <div className={css.infoWrapper}>
                    <UserInfo />
                </div>
            </div>
        </header>
    );
});
