import React from "react";
import { Field } from "formik";
import { titles } from "../../constants";
import css from "./DynamicFieldForm.module.css";

// Компонент для текстового типа полей
const DynamicTextFieldForm = ({ field, form }) => {
    let pattern;
    let title;
    switch (field) {
        case "name":
            pattern = "^[A-Za-zА-Яа-яІіЇїЄєҐґ'’]{2,50}$";
            title = "Ім'я повинно містити тільки літери та  апостроф ', довжина імені від 2 до 50 символів";
            break;
        case "surname":
            pattern = "^[A-Za-zА-Яа-яІіЇїЄєҐґ'’]{2,50}$";
            title = "Прізвище повинно містити тільки літери, '-', апостроф ', довжина імені від 2 до 70 символів";
            break;
        case "price":
            pattern = "[0-9]+";
            title = "Ціна повинна бути цілим числом";
            break;
        default:
            pattern = null;
    }

    return (
        <label htmlFor={field} className={css.formLabel}>
            Введіть {titles[field]}
            <Field type="text" name={field} placeholder={titles[field]} pattern={pattern} title={title} required className={css.formInput} />
        </label>
    );
};

export default DynamicTextFieldForm;
