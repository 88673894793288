import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useLocation,  useNavigate } from 'react-router-dom';
import { Formik, Form } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { FiBell } from "react-icons/fi";
import isEmpty from "lodash.isempty";
import { apiGetColumnsTable } from '../../api/common';
import Button from '../../ui/buttons/Button/Button';
import DynamicFieldFormControl from "../DynamicFieldForm/DynamicFieldFormControl";
// import * as workhoursAPI from "../../api/workhours";
// import * as doctorsAPI from "../../api/doctors";
// import * as servicesAPI from "../../api/services";
import { useAuth } from "../../hooks/useAuth";
// import {useDirectory} from "../../hooks/useDirectory";
import {createDirectory, updateDirectory} from "../../redux/directories/directoriesOperations";
import css from "./DirectoryForm.module.css";


const DirectoryForm = ({type})=>{

    const location = useLocation();
    const dispatch = useDispatch();
    const { user } = useAuth();
    // const {directory} = useDirectory();

    const [tableColumns, setTableColumns]=useState([]);
    const [initialValues, setInitialValues] = useState({});

    const navigate = useNavigate();

   
   
    useEffect(() => {

        const getColumnsTable=async()=>{
            try{
                const result=await apiGetColumnsTable(location.state?.item);
                setTableColumns(result);
            }catch(error){
                const errorMessage="Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
                toast.error(errorMessage);
            };
        }

        if(location?.state?.item && type==="add"){
            getColumnsTable();
        }
        if(location?.state?.item && type==="edit"){
            const arr=Object.keys(location?.state?.item);
            setTableColumns(arr);

        }
    },[location?.state?.item, type]);



    useEffect(() => {
        if(!tableColumns.length>0){
            return;
        }
 
        // Инициализация начальных значений для каждого поля
        const initial = {};
        for (let i = 0; i < tableColumns?.length; i += 1) {
            if(type==="add"){
                if (tableColumns[i].includes("id") || tableColumns[i].includes("active") || tableColumns[i].includes("user") || tableColumns[i].includes("createdAt") || tableColumns[i].includes("updatedAt")) {
                    continue;
                }
            }
            if(type==="edit"){
                if (tableColumns[i].includes("id") || tableColumns[i].includes("user") || tableColumns[i].includes("createdAt") || tableColumns[i].includes("updatedAt")) {
                    continue;
                }
            }

            if (tableColumns[i].includes("time")){
                if(type==="add"){
                    initial.hours = "";
                    initial.minutes = "";
                }
                if(type==="edit"){
                    initial.hours = location?.state?.item[tableColumns[i]].slice(0,2);
                    initial.minutes = location?.state?.item[tableColumns[i]].slice(3,5);                 
                }
                continue;

            }
            if(type==="add"){
                initial[tableColumns[i]] = "";
            }
            if(type==="edit"){
                initial[tableColumns[i]] = location?.state?.item[tableColumns[i]];
            }
          
        }

        setInitialValues(initial);
    },[tableColumns, type, location?.state?.item]);

    
    const handleSubmit=async (values, { resetForm })=>{
        let query={};
        // let res;
        let nameDirectory;

        if(type==="add"){
            if(location?.state?.item==="workhours"){
                const timeForQuery=values.hours.padStart(2, '0')+":"+values.minutes.padStart(2, '0')+":00";
                query={time: timeForQuery, user_id:user.user_id, active: true};
    
            }else{
                query={...values, user_id:user.user_id, active: true};
            }
            
            try{
               
                // switch (location?.state?.item) {
                //     case 'workhours':
                //         res=await workhoursAPI.apiAddWorkhour(query);                  
                //         break;
                //     case "doctors":
                //         res=await doctorsAPI.apiAddDoctor(query);         
                //         break;
                //     case "services":
                //         res=await servicesAPI.apiAddService(query);         
                //         break;
                //     default:
                //         console.log("Invalid type");
            
                // }      

                nameDirectory=location?.state?.item;
                dispatch(createDirectory({nameDirectory, query}));
                   
                toast.error(`Відправка форми`, {
                duration: 3000,
                position: "top-center",
                icon: <FiBell size={24} color={getComputedStyle(document.documentElement).getPropertyValue('--accent-color')} />
    
                })
            }catch(error){
                const errorMessage="Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
                toast.error(errorMessage);
            };

        }

        if(type==="edit"){ 

            const nameID=tableColumns[0].slice(0,tableColumns[0].length-3);
            const id=location?.state?.item[tableColumns[0]];
            if(nameID==="workhour"){
                const timeForQuery=values.hours.padStart(2, '0')+":"+values.minutes.padStart(2, '0')+":00";
                query={time: timeForQuery, user_id:user.user_id, active: values.active };
    
            }else{
                query={...values, user_id:user.user_id};
            }
            nameDirectory=nameID+"s";

            try{

                // switch (nameID+"s") {
                //     case 'workhours':
                //         res=await workhoursAPI.apiUpdateWorkhour(query, id);                  
                //         break;
                //     case "doctors":
                //         res=await doctorsAPI.apiUpdateDoctor(query, id);         
                //         break;
                //     case "services":
                //         res=await servicesAPI.apiUpdateService(query, id);         
                //         break;
                //     default:
                //         console.log("Invalid type");
            
                // }
                dispatch(updateDirectory({nameDirectory,query, id}));
                   
               
                toast.success(`Зміни внесені`, {
                duration: 3000,
                position: "top-center",
                icon: <FiBell size={24} color={getComputedStyle(document.documentElement).getPropertyValue('--accent-color')} />
    
                })
            }catch(error){
                const errorMessage="Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
                toast.error(errorMessage);
            };

        }
        
        handleCancel(resetForm);
        
    }


    const handleCancel=(resetForm)=>{
        resetForm();  
        // Переход на предыдущую страницу
        navigate(-1);

    }
    
    return (
        <>
        <div className="container">
            {type==="add" && <h2>Додавання запису до таблиці {location?.state?.item}</h2>}
            {type==="edit" && <h2>Редагування запису</h2>}
            <Toaster />
            {!isEmpty(initialValues) &&
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                isValidating
                
            >
                {({resetForm}) => (
                    <Form>

                        {tableColumns?.map((column, index) => {
                            let isRender=true;
                            if (type==="add"){
                                isRender = (column.includes("id") || column.includes("active") || column.includes("user") || column.includes("createdAt") || column.includes("updatedAt")) ? false: true;
                            }
                            if(type==="edit"){
                                isRender = (column.includes("id") || column.includes("user") || column.includes("createdAt") || column.includes("updatedAt")) ? false: true;
                            }
                         
                            return(
                                <React.Fragment key={`${column}${index}`}>
                                {isRender &&
                                (
                                    <DynamicFieldFormControl field={column}/>
                                )
                                }     
                                </React.Fragment>
                            
                            );

                        })}

                    <div className={css.butWrapper}>
                        {type==="add" &&  <Button type="submit">Додати запис у довідник</Button>}
                        {type==="edit" &&  <Button type="submit">Зберегти зміни у довіднику</Button>}
                        <Button type="button" onClick={() => handleCancel(resetForm)}>Відміна</Button>
                    </div>   


                    </Form>
             )}
            </Formik>
            }
        </div>
        </>

    );
}

export default DirectoryForm;