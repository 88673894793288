import DynamicTextFieldForm from "./DynamicTextFieldForm";
import DynamicTimeFieldForm from "./DynamicTimeFieldForm";
import DynamicActiveFieldForm from "./DynamicActiveFieldForm";

// Компонент для различных типов полей
const DynamicFieldFormControl = ({field}) => {

    //По умолчанию будет текстовое поле input
    switch (field) {
         
        case "time":
            return (
                <DynamicTimeFieldForm field={field}/>
            );
        case "active":
            return(
                <DynamicActiveFieldForm field={field}/>
            );
        default:
            return (
                <DynamicTextFieldForm field={field}/>
            );
    }



};

export default DynamicFieldFormControl;