import { forwardRef } from "react"
import { ReactComponent as Burger } from "../../../assets/icons/menu.svg";


import css from "./MobileMenuBtn.module.css"

export const MobileMenuBtn = forwardRef(({onClick, disabled}, ref) => {
    return (
        <button aria-label="open mobile sidebar" ref={ref} onClick={onClick} disabled={disabled} className={css.mobileMenuBtn}>
            <Burger className={css.icon}/>
        </button>
    )
});
