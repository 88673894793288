import { createSlice } from "@reduxjs/toolkit";
import { createSchedule, getSchedules, deleteSchedule } from "./schedulesOperations";
import { getDate, getYear, getWeek } from "date-fns";

export const initialState = {
    scheduleMonth: new Date().getMonth(),
    scheduleDay: getDate(new Date()),
    scheduleYear: getYear(new Date()),
    // choosedDate: new Date().toISOString(),
    /** week */
    scheduleWeek: getWeek(new Date()),
    /** week */
    items: [],
    isLoadingSchedules: false,
    error: null,

};

export const handlePending = (state, action) => {
    state.isLoadingSchedules = true;

};

export const handleRejected = (state, action) => {
    state.isLoadingSchedules = false;
    state.error = action.payload;
};

export const handleFulfilled = (state, action) => {
    state.items = action.payload;
    state.isLoadingSchedules = false;
    state.error = null;
};

export const handleCreateFulfilled = (state, action) => {
    state.items.push(action.payload);
    state.error = null;
};

// export const handleUpdateFulfilled = (state, action) => {
//     const oldItemIndex = state.items.findIndex((schedule) => schedule.schedule_id === action.payload.schedule_id);
//     state.items.splice(oldItemIndex, 1, action.payload);
// };

export const handleDeleteFulfilled = (state, action) => {
    state.items = state.items.filter((schedule) => schedule.schedule_id !== action.payload);
    state.error = null;
};

const scheduleSlice = createSlice({
    name: "schedules",
    initialState,
    reducers: {
        setScheduleCurrentDay(state, action) {
            state.scheduleDay = action.payload;
        },
        setScheduleCurrentMonth(state, action) {
            state.scheduleMonth = action.payload;
        },
        setScheduleCurrentYear(state, action) {
            state.scheduleYear = action.payload;
        },

        // /** week */
        // setScheduleCurrentWeek(state, action) {
        //     state.scheduleWeek = action.payload;
        // },
        // /** week */
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSchedules.pending, handlePending)
            .addCase(getSchedules.fulfilled, handleFulfilled)
            .addCase(getSchedules.rejected, handleRejected)
            .addCase(deleteSchedule.fulfilled, handleDeleteFulfilled)
            .addCase(createSchedule.fulfilled, handleCreateFulfilled);
    },
});

export const { setScheduleCurrentDay, setScheduleCurrentMonth, setScheduleCurrentYear, setScheduleCurrentWeek } = scheduleSlice.actions;

export const scheduleReducer = scheduleSlice.reducer;
