import { instance } from "./instance";

export const apiGetServices = async () => {
    try{     
        const result = await instance.get(`/api/services`);
        return result.data;
    }catch (error){
        throw new Error(error.message);
    }
}

export const apiAddService = async (service) => {
    try {
        const { data } = await instance.post(`/api/services`, service);
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiUpdateService = async (service, id) => {
    try {
        const { data } = await instance.put(`/api/services/${id}`, service);
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiDeleteService = async (id) => {
    try {
        const { data } = await instance.delete(`/api/services/${id}`);
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};