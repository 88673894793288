import { createSlice } from "@reduxjs/toolkit";
import { logInUser, logOutUser, refreshUser } from "./authOperations";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
    user: {
        user_id: null,
        login: null,
        // email: null,
        avatarURL: null,
    },
    token: null,
    isLoggedIn: false,
    isRefreshing: false,

    error: null,
    isLoading: false,
};

const handlePending = (state) => {
    state.isLoading = true;
    //** */
    state.isLoadingAuth = true;
    /** */
};

const handleFulfilled = (state, action) => {
    state.user = action.payload.user;
    state.token = action.payload.token;
    state.isLoggedIn = true;

    state.error = null;
    state.isLoading = false;
};

const handleRejected = (state, action) => {
    state.error = action.payload;
    state.isLoading = false;
};
const handleLogOutFulfilled = (state) => {
    state.user = { name: null, email: null };
    state.token = null;
    state.isLoggedIn = false;
};

const handleRefreshUserPending = (state) => {
    state.isRefreshing = true;
};

const handleRefreshUserFulfilled = (state, action) => {
    state.user = action.payload;
    state.isLoggedIn = true;
    state.isRefreshing = false;
};

const handleRefreshUserRejected = (state) => {
    state.isRefreshing = false;
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearError(state) {
            state.error = null;
        },
        setToken(state, action) {
            state.token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logInUser.pending, handlePending)
            .addCase(logInUser.fulfilled, handleFulfilled)
            .addCase(logInUser.rejected, handleRejected)
            .addCase(logOutUser.fulfilled, handleLogOutFulfilled)
            //
            // .addCase(logOutUser.pending, handlePending)
            // .addCase(refreshUser.pending, (state) => {
            //     state.isRefreshing = true;
            // })
            // .addCase(refreshUser.fulfilled, (state, action) => {
            //     state.user = action.payload;
            //     state.isLoggedIn = true;
            //     state.isRefreshing = false;
            //     state.error = null;
            // })
            // .addCase(refreshUser.rejected, (state) => {
            //     state.isRefreshing = false;
            // })
            //
            .addCase(refreshUser.pending, handleRefreshUserPending)
            .addCase(refreshUser.fulfilled, handleRefreshUserFulfilled)
            .addCase(refreshUser.rejected, handleRefreshUserRejected);
    },
});

const authPersistConfig = {
    key: "auth",
    storage,
    whitelist: ["token"],
};

export const { clearError } = authSlice.actions;

export const { setToken } = authSlice.actions;
export const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
