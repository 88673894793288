import { instance } from "./instance";

export const apiGetSchedules = async (paramsString) => {
    const searchParams = new URLSearchParams(paramsString);
    try {
        const { data } = await instance.get(`/api/schedules?${searchParams}`);
        return data;

    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiDeleteSchedule = async (id) => {
    try {
        const { data } = await instance.delete(`/api/schedules/${id}`);
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiAddSchedule = async (schedule) => {
    try {
        const response = await instance.post(`/api/schedules`, schedule);
        console.log("apiAddSchedule response=", response)
        return response.data;
    } catch (error) {
        console.log("error=", error)
        throw new Error(error.message);
    }
};