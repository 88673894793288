import React, { useState, Suspense, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import CalendarToolbar from "../../components/CalendarToolbar/CalendarToolbar";
import { useSelector, useDispatch } from "react-redux";
import { selectDay, selectMonth, selectYear, selectWeek } from "../../redux/appointments/selectors";
import Loader from "../../ui/Loader/Loader";
import { getAppointments } from "../../redux/appointments/operations";

const CalendarPage = () => {
    // console.log("CalendarPage");
    const navigate = useNavigate();
    const month = useSelector(selectMonth);
    const day = useSelector(selectDay);
    const year = useSelector(selectYear);
    const week = useSelector(selectWeek);

    const dispatch = useDispatch();

    const { pathname } = useLocation();
    const initialPeriodType = pathname.includes("/calendar/day") ? "day" : pathname.includes("/calendar/week") ? "week" : "month";

    //  console.log("initialPeriodType=", initialPeriodType);

    // useEffect(() => {
    //     updatePageName("Calendar");
    // }, [updatePageName]);

    const [periodType, setPeriodType] = useState(initialPeriodType || "month");
    const [currentDateMonth] = useState(month);
    const [currentDateYear] = useState(year);

    useEffect(() => {
        if (periodType === "month") {
            dispatch(getAppointments("month"));
            navigate(`/calendar/month/${month}`);
        }
        if (periodType === "week") {
            dispatch(getAppointments("week"));
            navigate(`/calendar/week/${week}`);
        } else if (periodType === "day") {
            dispatch(getAppointments("day"));
            navigate(`/calendar/day/${day}`);
        }
    }, [navigate, periodType, day, month, week, dispatch]);

    const checkDate = (date) => {
        const currentDate = new Date(currentDateYear, currentDateMonth, 2);
        if (date > currentDate) {
            return false;
        }
        return true;
    };

    return (
        <>
            <h2 className="caption">
                <center>Календар прийомів</center>
            </h2>
            <CalendarToolbar periodType={periodType} changePeriod={setPeriodType} checkDate={checkDate} page={"calendar"} />
            <Suspense fallback={<Loader type={"suspense"} />}>
                <Outlet context={[setPeriodType, checkDate]} />
            </Suspense>
        </>
    );
};

export default CalendarPage;
