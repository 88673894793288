import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import toast, { Toaster } from "react-hot-toast";
import Button from "../../ui/buttons/Button/Button";
import { createSchedule } from "../../redux/schedules/schedulesOperations";
import { useAuth } from "../../hooks/useAuth";
import css from "./ModalAddDoctor.module.css";
import "../../styles/variables.css";

const ModalAddDoctor = ({ currentItem, onClose, text, type, date, doctors, time }) => {
    const dispatch = useDispatch();
    const initialValues = {
        doctor: `${doctors[0]?.name} ${doctors[0]?.surname}`,
    };

    const { user } = useAuth();

    const handleSubmit = (values, { resetForm }) => {
        const doctorForm = doctors.find((doctor) => `${doctor.name} ${doctor.surname}` === values.doctor);
        try {
            const query = {
                date: date,
                time: time,
                doctor_id: doctorForm.doctor_id,
                user_id: user.user_id,
            };
            dispatch(createSchedule(query));
        } catch (error) {
            const errorMessage = "Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
            toast.error(errorMessage);
            console.log("errorMessage=", errorMessage);
        }
        handleCancel(resetForm);
    };

    const handleCancel = (resetForm) => {
        resetForm();
        onClose();
    };

    return (
        <div className={css.wrapper}>
            <p>{text}</p>
            <Toaster />
            <Formik initialValues={initialValues} onSubmit={handleSubmit} isValidating>
                {({ values, setFieldValue, resetForm }) => (
                    <Form>
                        <div role="group" aria-labelledby="my-radio-group" className={css.doctorsWrapper}>
                            {doctors?.length > 0 &&
                                doctors.map(({ doctor_id, name, surname }) => (
                                    <label className={css.customRadio} key={`doctor${doctor_id}`}>
                                        <Field type="radio" name="doctor" value={`${name} ${surname}`} />
                                        <span>{`${name} ${surname}`}</span>
                                    </label>
                                ))}
                        </div>

                        <div className={css.butWrapper}>
                            <Button type="submit" className="Ok">
                                Додати
                            </Button>
                            <Button type="button" onClick={() => handleCancel(resetForm)} className="cancel">
                                Відміна
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ModalAddDoctor;
