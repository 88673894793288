import { useSelector } from "react-redux";
import {
    selectAppointments,
    selectMonth,
    selectWeek,
    selectDay,
    selectYear,
    selectIsLoadingAppointments,
    selectFullDate,
    selectErrorAppointments,
    selectStatusAppointments

} from "../redux/appointments/selectors";

export const useAppointment = () => {
    const appointments = useSelector(selectAppointments);
    const month = useSelector(selectMonth);
    const week = useSelector(selectWeek);
    const day = useSelector(selectDay);
    const year = useSelector(selectYear);
    const isLoading = useSelector(selectIsLoadingAppointments);
    const appointmentFullDate=useSelector(selectFullDate);
    const appointmentError=useSelector(selectErrorAppointments);
    const appointmentStatus=useSelector(selectStatusAppointments);



    return {
        appointments,
        month,
        week,
        day,
        year,
        isLoading,
        appointmentFullDate,
        appointmentError,
        appointmentStatus
    };
};
