import AdminNavigation from "../../components/AdminNavigation/AdminNavigation";


const AdminPage = () => {
    return (
    <>
        <h2><center>Адміністративна панель</center></h2>
        <AdminNavigation/>
    </>

    )
    
    ;
};

export default AdminPage;
